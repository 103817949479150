import { Suspense, lazy } from 'react';

import SuspenseLoader from 'src/components/SuspenseLoader';

const Loader = (Component) => (props) =>
  (
    <Suspense fallback={<SuspenseLoader />}>
      <Component {...props} />
    </Suspense>
  );

// Management

const Users = Loader(lazy(() => import('src/content/management/Users')));
const SingleUser = Loader(lazy(() => import('src/content/management/Users/single')));

const Roles = Loader(lazy(() => import('src/content/management/Roles')));

const Services = Loader(
  lazy(() => import('src/content/management/Services/ServicesList'))
);

const managementRoutes = [
  {
    path: 'users',
    children: [
      {
        path: '',
        element: <Users />
      },
      {
        path: ':userId',
        element: <SingleUser />
      }
    ]
  },
  {
    path: 'roles',
    children: [
      {
        path: '',
        element: <Roles />
      }
    ]
  },
  {
    path: 'services',
    children: [
      {
        path: '',
        element: <Services />
      }
    ]
  }
];

export default managementRoutes;
