import { useEffect } from 'react';
import * as signalR from '@microsoft/signalr';
import { useDispatch } from '../store';
import { setNotifications } from '../slices/notifications';

export const useSignal = () => {
  const dispatch = useDispatch();

  useEffect(() => {
    const connection = new signalR.HubConnectionBuilder()
      .withUrl(`${process.env.REACT_APP_API_URL}/notificationHub`) // Укажите URL SignalR-хаба
      .withAutomaticReconnect() // Автоматическое переподключение
      .configureLogging(signalR.LogLevel.Information)
      .build();

    connection
      .start()
      .then(() => console.log('SignalR Connected'))
      .catch((err) => console.error('Connection failed: ', err));

    connection.on('ReceiveNotification', (notificationType, message) => {
      console.log('New message:', message);
      console.log('Notification type:', notificationType);

      dispatch(
        setNotifications([
          {
            data: message,
            type: notificationType
          }
        ])
      );
    });

    return () => {
      connection.stop().then(() => console.log('SignalR Disconnected'));
    };
  }, []);
};
