import { combineReducers } from '@reduxjs/toolkit';
import { reducer as calendarReducer } from 'src/slices/calendar';
import { reducer as projectsBoardReducer } from 'src/slices/projects_board';
import { reducer as mailboxReducer } from 'src/slices/mailbox';
import { reducer as countriesReducer } from 'src/slices/countries';
import { dashboardReducer } from '../slices/dashboard';
import { notificationsReducer } from '../slices/notifications';

const rootReducer = combineReducers({
  calendar: calendarReducer,
  projectsBoard: projectsBoardReducer,
  mailbox: mailboxReducer,
  countries: countriesReducer,
  dashboard: dashboardReducer,
  notifications: notificationsReducer
});

export default rootReducer;
