import type { PayloadAction } from '@reduxjs/toolkit';
import { createSlice } from '@reduxjs/toolkit';
import type { AppThunk } from '../store';
import axios from '../utils/axios';
import { IDashboard } from '../models/dashboard';
import {
  getCurrentMonthRange,
  getCurrentTodayRange,
  getCurrentYearRange,
  getCurrentYesterdayRange
} from '../utils/dateUtils';

export type Period = 'today' | 'yesterday' | 'last_month' | 'last_year';

interface DashboardState {
  data: IDashboard | null;
}

const initialState: DashboardState = {
  data: null
};

const slice = createSlice({
  name: 'dashboard',
  initialState,
  reducers: {
    setData(state: DashboardState, action: PayloadAction<IDashboard>) {
      state.data = action.payload;
    }
  }
});

export const getDashboardData =
  (period: Period): AppThunk =>
  async (dispatch) => {
    switch (period) {
      case 'today': {
        const period = getCurrentTodayRange();
        const response = await axios.post<IDashboard>('/Dashboard', {
          ...period
        });

        dispatch(slice.actions.setData(response.data));
        break;
      }
      case 'yesterday': {
        const period = getCurrentYesterdayRange();
        const response = await axios.post<IDashboard>('/Dashboard', {
          ...period
        });

        dispatch(slice.actions.setData(response.data));
        break;
      }
      case 'last_year': {
        const period = getCurrentYearRange();
        const response = await axios.post<IDashboard>('/Dashboard', {
          ...period
        });

        dispatch(slice.actions.setData(response.data));
        break;
      }
      default: {
        const period = getCurrentMonthRange();
        const response = await axios.post<IDashboard>('/Dashboard', {
          ...period
        });

        dispatch(slice.actions.setData(response.data));
      }
    }
  };

export const dashboardReducer = slice.reducer;
