import {
  alpha,
  Avatar,
  Badge,
  Box,
  Button,
  Divider,
  IconButton,
  List,
  ListItem,
  ListItemAvatar,
  Popover,
  styled,
  Tooltip,
  Typography
} from '@mui/material';
import { useRef, useState } from 'react';
import NotificationsActiveTwoToneIcon from '@mui/icons-material/NotificationsActiveTwoTone';
import { useTranslation } from 'react-i18next';
import { formatDistance, subDays } from 'date-fns';
import { useSelector } from '../../../../../store';
import { FeedbackTwoTone } from '@mui/icons-material';

function HeaderNotifications() {
  const { data } = useSelector((state) => state.notifications);
  const ref = useRef<any>(null);
  const [isOpen, setOpen] = useState<boolean>(false);
  const [isFullHeight, setFullHeight] = useState<boolean>(false);

  const handleOpen = (): void => {
    setOpen(true);
  };

  const handleClose = (): void => {
    setOpen(false);
  };

  const { t }: { t: any } = useTranslation();

  return (
    <>
      <Tooltip arrow title={t('Notifications')}>
        <IconButtonWrapper color="primary" ref={ref} onClick={handleOpen}>
          <NotificationsBadge
            badgeContent={data.length}
            anchorOrigin={{
              vertical: 'top',
              horizontal: 'right'
            }}
          >
            <NotificationsActiveTwoToneIcon />
          </NotificationsBadge>
        </IconButtonWrapper>
      </Tooltip>
      <Popover
        anchorEl={ref.current}
        onClose={handleClose}
        open={isOpen}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'right'
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right'
        }}
        PaperProps={{
          sx: {
            bottom: isFullHeight ? '16px' : 'auto',
            minWidth: '400px',
            display: 'flex',
            flexDirection: 'column'
          }
        }}
      >
        <Box
          sx={{
            padding: '16px 12px 16px 16px',
            display: 'flex',
            alignItems: 'center',
            borderBottom: '1px solid #0000000D',
            gap: '10px'
          }}
          display="flex"
          justifyContent="space-between"
        >
          <Typography variant="h5">{t('Notifications')}</Typography>
          <Button
            size={'small'}
            sx={{
              textTransform: 'none'
            }}
          >
            {t('Mark all as read')}
          </Button>
        </Box>
        <List
          sx={{
            p: 2,
            flexGrow: 1
          }}
        >
          {data.length === 0 && (
            <ListItem>
              <Typography
                sx={{ margin: 'auto' }}
                variant="body2"
                color="text.secondary"
              >
                {t('No new notifications')}
              </Typography>
            </ListItem>
          )}

          {data.map((notification, index) => {
            return (
              <ListItem
                key={index}
                sx={{
                  display: { xs: 'block', sm: 'flex' },
                  minWidth: '400px'
                }}
              >
                <ListItemAvatar
                  sx={{
                    mb: { xs: 1, sm: 0 }
                  }}
                >
                  <Avatar
                    sx={{
                      height: '48px',
                      width: '48px',
                      background: '#ECFBE6'
                    }}
                  >
                    <FeedbackTwoTone sx={{ fill: '#3FBF02' }} />
                  </Avatar>
                </ListItemAvatar>
                <Box flex={1}>
                  <Box display="flex" justifyContent="space-between">
                    <Typography
                      sx={{
                        fontWeight: 'bold'
                      }}
                    >
                      TLV System
                    </Typography>
                    <Typography
                      variant="caption"
                      sx={{
                        textTransform: 'none'
                      }}
                    >
                      {formatDistance(subDays(new Date(), 3), new Date(), {
                        addSuffix: true
                      })}
                    </Typography>
                  </Box>
                  <Typography
                    component="p"
                    variant="body2"
                    color="text.secondary"
                    sx={{
                      maxWidth: '300px'
                    }}
                    dangerouslySetInnerHTML={{ __html: notification.data }}
                  >
                    {/*{notification.data}*/}
                  </Typography>
                </Box>
              </ListItem>
            );
          })}
        </List>
        <Divider />
        <Box m={1}>
          {isFullHeight ? (
            <Button
              color="secondary"
              fullWidth
              onClick={() => {
                setFullHeight(false);
              }}
            >
              {t('View less notifications')}
            </Button>
          ) : (
            <Button
              color="secondary"
              fullWidth
              onClick={() => {
                setFullHeight(true);
              }}
            >
              {t('View more notifications')}
            </Button>
          )}
        </Box>
      </Popover>
    </>
  );
}

const AnimatedBadge = styled(Badge)(
  ({ theme }) => `
    
    .MuiBadge-badge {
        box-shadow: 0 0 0 2px ${theme.palette.background.paper};
        background-color: #44b700;
        color: #44b700;
        
        &::after {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            border-radius: 50%;
            animation: ripple 1.2s infinite ease-in-out;
            border: 1px solid currentColor;
            content: "";
        }
    }
`
);

const NotificationsBadge = styled(Badge)(
  ({ theme }) => `
    
    .MuiBadge-badge {
        background-color: ${alpha(theme.palette.error.main, 0.1)};
        color: ${theme.palette.error.main};
        min-width: 16px; 
        height: 16px;
        padding: 0;

        &::after {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            border-radius: 50%;
            box-shadow: 0 0 0 1px ${alpha(theme.palette.error.main, 0.3)};
            content: "";
        }
    }
`
);

const IconButtonWrapper = styled(IconButton)(
  ({ theme }) => `
        width: ${theme.spacing(7)};
        height: ${theme.spacing(7)};
`
);

export default HeaderNotifications;
