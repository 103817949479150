import type { PayloadAction } from '@reduxjs/toolkit';
import { createSlice } from '@reduxjs/toolkit';
import { NotificationDto } from '../models/notification';
import { mergeUniqueById } from '../utils/mergeUniqueById';

interface NotificationState {
  data: NotificationDto[];
}

const initialState: NotificationState = {
  data: []
};

const slice = createSlice({
  name: 'notifications',
  initialState,
  reducers: {
    addNewNotification(
      state: NotificationState,
      action: PayloadAction<NotificationDto[]>
    ) {
      state.data = mergeUniqueById(action.payload, state.data);
    }
  }
});

export const notificationsReducer = slice.reducer;

export const { addNewNotification } = slice.actions;
