import { Suspense, lazy } from 'react';

import SuspenseLoader from 'src/components/SuspenseLoader';

const Loader = (Component) => (props) =>
  (
    <Suspense fallback={<SuspenseLoader />}>
      <Component {...props} />
    </Suspense>
  );

// Invoices
const ReservationList = Loader(
  lazy(() => import('src/content/general/Reservations/ReservationList'))
);
const reservationRoutes = [
  {
    path: '',
    element: <ReservationList />
  }
];

export default reservationRoutes;
