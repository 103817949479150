import { Suspense, lazy } from 'react';

import SuspenseLoader from 'src/components/SuspenseLoader';

const Loader = (Component) => (props) =>
  (
    <Suspense fallback={<SuspenseLoader />}>
      <Component {...props} />
    </Suspense>
  );

// Payment Vouchers
const PaymentVoucherList = Loader(
  lazy(() => import('src/content/general/PaymentVouchers/PaymentVoucherList'))
);
const PaymentVoucherView = Loader(
  lazy(() => import('src/content/general/PaymentVouchers/payment-voucher-view/PaymentVoucherView'))
);

const assetsRoutes = [
  {
    path: '',
    element: <PaymentVoucherList />
  },
  {
    path: ':paymentVoucherId',
    element: <PaymentVoucherView />
  }
];

export default assetsRoutes;
