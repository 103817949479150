import { Suspense, lazy } from 'react';

import SuspenseLoader from 'src/components/SuspenseLoader';

const Loader = (Component) => (props) =>
  (
    <Suspense fallback={<SuspenseLoader />}>
      <Component {...props} />
    </Suspense>
  );

// Invoices
const Invoices = Loader(
  lazy(() => import('src/content/general/Invoices/InvoicesList'))
);
const InvoiceView = Loader(
  lazy(() => import('src/content/general/Invoices/invoiceView'))
);

const assetsRoutes = [
  {
    path: '',
    element: <Invoices />
  },
  {
    path: ':invoiceId',
    element: <InvoiceView />
  }
];

export default assetsRoutes;
