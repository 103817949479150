import { useEffect } from 'react';
import * as signalR from '@microsoft/signalr';
import { useDispatch } from '../store';
import { addNewNotification, setTotalUnread } from '../slices/notifications';
import axios from '../utils/axios';

export const useSignal = () => {
  const dispatch = useDispatch();

  useEffect(() => {
    axios
      .post('/notifications/list', {
        limit: 10,
        offset: 0
      })
      .then((res) => {
        dispatch(addNewNotification(res.data.items));
        dispatch(setTotalUnread(res.data.totalUnread));
      });
  }, []);

  useEffect(() => {
    const connection = new signalR.HubConnectionBuilder()
      .withUrl(`${process.env.REACT_APP_API_URL}/notificationHub`) // Укажите URL SignalR-хаба
      .withAutomaticReconnect() // Автоматическое переподключение
      .configureLogging(signalR.LogLevel.Information)
      .build();

    connection
      .start()
      .then(() => console.log('SignalR Connected'))
      .catch((err) => console.error('Connection failed: ', err));

    connection.on('ReceiveNotification', (message) => {
      console.log('New message:', message);

      // dispatch(
      //   addNewNotification()
      // );
    });

    return () => {
      connection.stop().then(() => console.log('SignalR Disconnected'));
    };
  }, []);
};
