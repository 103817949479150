import type { PayloadAction } from '@reduxjs/toolkit';
import { createSlice } from '@reduxjs/toolkit';
import type { AppThunk } from '../store';
import axios from '../utils/axios';
import { IDashboard } from '../models/dashboard';
import {
  getCurrentMonthRange,
  getCurrentTodayRange,
  getCurrentYearRange,
  getCurrentYesterdayRange
} from '../utils/dateUtils';

export type NotificationType = 'SystemUpdate';

interface INotification {
  type: NotificationType;
  data: string;
}

interface NotificationState {
  data: INotification[];
}

const initialState: NotificationState = {
  data: []
};

const slice = createSlice({
  name: 'notifications',
  initialState,
  reducers: {
    setNotifications(
      state: NotificationState,
      action: PayloadAction<INotification[]>
    ) {
      state.data = [...action.payload, ...state.data];
    }
  }
});

export const notificationsReducer = slice.reducer;

export const { setNotifications } = slice.actions;
