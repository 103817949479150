// src/utils/dateUtils.ts

/**
 * Pads a single-digit number with a leading zero.
 * @param num - The number to pad.
 * @returns A string with the padded number.
 */
function padZero(num: number): string {
  return num < 10 ? `0${num}` : `${num}`;
}

/**
 * Parses a date input (string or Date object) into a Date object.
 * @param dateInput - The date input to parse.
 * @returns A Date object or null if invalid.
 */
function parseDate(dateInput: string | Date): Date | null {
  if (!dateInput) return null;

  if (dateInput instanceof Date) {
    return isNaN(dateInput.getTime()) ? null : dateInput;
  }

  const parsedDate = new Date(dateInput);
  return isNaN(parsedDate.getTime()) ? null : parsedDate;
}

/**
 * Formats a date input into a string with the format 'dd/MM/yyyy'.
 * @param dateInput - The date input to format (string or Date object).
 * @returns A formatted date string or an empty string if invalid.
 */
export function formatDate(dateInput: string | Date): string {
  const date = parseDate(dateInput);
  if (!date) return '';

  const day = padZero(date.getDate());
  const month = padZero(date.getMonth() + 1); // Months are zero-based.
  const year = date.getFullYear();

  return `${day}/${month}/${year}`;
}

/**
 * Formats a date input into a string with the format 'dd/MM/yyyy HH:mm:ss'.
 * @param dateInput - The date input to format (string or Date object).
 * @returns A formatted datetime string or an empty string if invalid.
 */
export function formatDateTime(dateInput: string | Date): string {
  const date = parseDate(dateInput);
  if (!date) return '';

  const day = padZero(date.getDate());
  const month = padZero(date.getMonth() + 1);
  const year = date.getFullYear();

  const hours = padZero(date.getHours());
  const minutes = padZero(date.getMinutes());
  const seconds = padZero(date.getSeconds());

  return `${day}/${month}/${year} ${hours}:${minutes}:${seconds}`;
}

/**
 * Formats a date input into a string with the format 'HH:mm:ss'.
 * @param dateInput - The date input to format (string or Date object).
 * @returns A formatted time string or an empty string if invalid.
 */
export function formatTime(dateInput: string | Date): string {
  const date = parseDate(dateInput);
  if (!date) return '';

  const hours = padZero(date.getHours());
  const minutes = padZero(date.getMinutes());
  const seconds = padZero(date.getSeconds());

  return `${hours}:${minutes}:${seconds}`;
}

export function getMonthRange(year: number, month: number): string {
  // Создаём дату на начало (1-е число) нужного месяца
  // (month - 1, потому что в JS месяцы идут от 0 до 11)
  const start = new Date(Date.UTC(year, month, 1, 0, 0, 0, 0));

  // Создаём дату на начало следующего месяца
  const firstDayOfNextMonth = new Date(
    Date.UTC(year, month + 1, 1, 0, 0, 0, 0)
  );
  const endOfThisMonth = new Date(firstDayOfNextMonth.getTime() - 1);

  // Преобразуем обе даты в строки формата ISO
  const startStr = start.toISOString(); // начало месяца
  const endStr = endOfThisMonth.toISOString(); // конец месяца

  // Собираем итоговую строку
  return `${startStr} - ${endStr}`;
}

/**
 * Возвращает начало и конец *сегодняшнего* дня в UTC.
 * Пример результата:
 * { start: "2024-01-01T00:00:00.000Z", end: "2024-01-01T23:59:59.999Z" }
 */
export function getCurrentTodayRange() {
  const now = new Date();

  const year = now.getUTCFullYear();
  const month = now.getUTCMonth(); // 0-based
  const day = now.getUTCDate();

  const start = new Date(Date.UTC(year, month, day, 0, 0, 0, 0));
  const end = new Date(Date.UTC(year, month, day, 23, 59, 59, 999));

  return {
    from: start.toISOString(),
    to: end.toISOString()
  };
}

/**
 * Возвращает начало и конец *вчерашнего* дня в UTC.
 * Пример результата:
 * { start: "2024-01-01T00:00:00.000Z", end: "2024-01-01T23:59:59.999Z" }
 */
export function getCurrentYesterdayRange() {
  const now = new Date();

  const year = now.getUTCFullYear();
  const month = now.getUTCMonth();
  const day = now.getUTCDate();

  const start = new Date(Date.UTC(year, month, day - 1, 0, 0, 0, 0));
  const end = new Date(Date.UTC(year, month, day - 1, 23, 59, 59, 999));

  return {
    from: start.toISOString(),
    to: end.toISOString()
  };
}

/**
 * Возвращает начало и конец *текущего месяца* в UTC.
 * Пример результата:
 * { start: "2024-01-01T00:00:00.000Z", end: "2024-01-31T23:59:59.999Z" }
 */
export function getCurrentMonthRange() {
  const now = new Date();

  const year = now.getUTCFullYear();
  const month = now.getUTCMonth(); // 0-based

  // Начало месяца (1-е число в 00:00 UTC)
  const start = new Date(Date.UTC(year, month, 1, 0, 0, 0, 0));

  // Начало следующего месяца
  const nextMonthStart = new Date(Date.UTC(year, month + 1, 1, 0, 0, 0, 0));

  // Вычитаем 1 мс, чтобы получить конец текущего месяца (23:59:59.999)
  const end = new Date(nextMonthStart.getTime() - 1);

  return {
    from: start.toISOString(),
    to: end.toISOString()
  };
}

/**
 * Возвращает начало и конец *текущего года* в UTC.
 * Пример результата:
 * { start: "2024-01-01T00:00:00.000Z", end: "2024-12-31T23:59:59.999Z" }
 */
export function getCurrentYearRange() {
  const now = new Date();

  const year = now.getUTCFullYear();

  // Начало года (1 января в 00:00 UTC)
  const start = new Date(Date.UTC(year, 0, 1, 0, 0, 0, 0));

  // Начало следующего года
  const nextYearStart = new Date(Date.UTC(year + 1, 0, 1, 0, 0, 0, 0));

  // Вычитаем 1 мс, чтобы получить конец текущего года (31 декабря 23:59:59.999)
  const end = new Date(nextYearStart.getTime() - 1);

  return {
    from: start.toISOString(),
    to: end.toISOString()
  };
}
