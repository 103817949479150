import type { ReactNode } from 'react';

import AssignmentIndTwoToneIcon from '@mui/icons-material/AssignmentIndTwoTone';
import AssessmentIcon from '@mui/icons-material/Assessment';
import HouseIcon from '@mui/icons-material/House';
import DashboardIcon from '@mui/icons-material/Dashboard';
import SecurityIcon from '@mui/icons-material/Security';
import HistoryEduIcon from '@mui/icons-material/HistoryEdu';
import ReceiptLongIcon from '@mui/icons-material/ReceiptLong';
import ProductionQuantityLimitsIcon from '@mui/icons-material/ProductionQuantityLimits';
import PaymentsIcon from '@mui/icons-material/Payments';
import EventAvailableIcon from '@mui/icons-material/EventAvailable';

export interface MenuItem {
  link?: string;
  icon?: ReactNode;
  badge?: string;
  badgeTooltip?: string;

  items?: MenuItem[];
  name: string;
}

export interface MenuItems {
  items: MenuItem[];
  heading: string;
}

const menuItems: MenuItems[] = [
  {
    heading: 'General',
    items: [
      {
        name: 'Dashboard',
        icon: DashboardIcon,
        link: 'dashboard'
      },
      {
        name: 'Assets',
        icon: HouseIcon,
        link: 'assets'
      },
      {
        name: 'Reports',
        icon: AssessmentIcon,
        link: 'reports'
      },
      {
        name: 'Contracts',
        icon: HistoryEduIcon,
        link: 'contracts'
      },
      {
        name: 'Invoices',
        icon: ReceiptLongIcon,
        link: 'invoices'
      },
      {
        name: 'Payment Vouchers',
        icon: PaymentsIcon,
        link: 'payment-vouchers'
      },
      {
        name: 'Reservations',
        icon: EventAvailableIcon,
        link: 'reservations'
      }
    ]
  },
  {
    heading: 'Management',
    items: [
      {
        name: 'Users',
        icon: AssignmentIndTwoToneIcon,
        link: 'management/users'
      },
      {
        name: 'Roles',
        icon: SecurityIcon,
        link: 'management/roles'
      },
      {
        name: 'Services',
        icon: ProductionQuantityLimitsIcon,
        link: 'management/services'
      }
    ]
  }
];

export default menuItems;
