const enJSON = {
  ENUMS: {
    ASSET_STATE: {
      SHORTTERM: 'Short Term',
      LONGTERM: 'Long Term'
    },
    ASSET_STATUS: {
      ACTIVE: 'Active',
      PENDING: 'Pending',
      TERMINATED: 'Terminated',
      EXPIRED: 'Expired',
      CANCELLED: 'Cancelled'
    },
    CONTRACT_STATUS: {
      ACTIVE: 'Active',
      PENDING: 'Pending',
      TERMINATED: 'Terminated',
      EXPIRED: 'Expired',
      CANCELLED: 'Cancelled'
    },
    CONTRACT_PAYMENT_PERIOD: {
      Daily: 'Daily',
      Weekly: 'Weekly',
      Monthly: 'Monthly',
      Quarterly: 'Quarterly',
      SemiAnnually: 'Semi-Annually',
      Annually: 'Annually'
    }
  },
  INVOICES: {
    TITLE: 'Invoices',
    SUB_TITLE: "Here's a list of all your invoices",
    LIST: {
      NO_ITEMS: 'No invoices found.'
    },
    COLUMNS: {
      ID: {
        NAME: 'ID'
      },
      OWNER_NAME: {
        NAME: 'Owner'
      },
      STATUS: {
        NAME: 'Status'
      },
      TOTAL_AMOUNT: {
        NAME: 'Total Amount'
      },
      ACCOUNTED: {
        NAME: 'Accounted'
      },
      CREATED: {
        NAME: 'Created'
      },
      ACTIONS: {
        NAME: 'Actions'
      }
    }
  },
  PAYMENT_VOUCHER_LIST: {
    TITLE: 'Payment Vouchers',
    SUB_TITLE: "Here's a list of all your payment vouchers",
    LIST: {
      NO_ITEMS: 'No payment vouchers found.',
      ACTIONS: {
        VIEW: 'View'
      }
    },
    COLUMNS: {
      ID: {
        NAME: 'ID'
      },
      CUSTOMER_NAME: {
        NAME: 'Customer'
      },
      STATE: {
        NAME: 'State'
      },
      PERIOD: {
        NAME: 'Period'
      },
      CREATED: {
        NAME: 'Created'
      },
      STATUS: {
        NAME: 'Status'
      },
      TOTAL_AMOUNT: {
        NAME: 'Amount'
      },
      ACTIONS: {
        NAME: 'Actions'
      }
    }
  },
  PAYMENT_VOUCHER_VIEW: {
    TITLE: 'Payment Vouchers',
    SUB_TITLE: "Here's a list of all your payment vouchers"
  },
  RESERVATION_LIST: {
    TITLE: 'Reservations',
    SUB_TITLE: "Here's a list of all your Reservations",

    COLUMNS: {
      CREATED: {
        NAME: 'Created'
      },
      FROM: {
        NAME: 'From'
      },
      TO: {
        NAME: 'TO'
      },
      TYPE: {
        NAME: 'Type'
      },
      STATUS: {
        NAME: 'Status'
      },
      PROCESS: {
        NAME: 'Process'
      },
      ASSET: {
        NAME: 'Asset'
      },
      CHANNEL: {
        NAME: 'Channel'
      },
      AMOUNT: {
        NAME: 'AMOUNT'
      },
      ACTIONS: {
        NAME: 'Actions'
      }
    },

    LIST: {
      ITEM: {
        ACTIONS: {
          VIEW: 'View in Lodgify'
        }
      }
    }
  },
  CONTRACT_LIST: {
    TITLE: 'Contracts',
    SUB_TITLE: "Here's a list of all your contracts",
    COLUMNS: {
      ID: {
        NAME: 'ID'
      },
      ASSET_NAME: {
        NAME: 'Asset Name'
      },
      PROJECT_NAME: {
        NAME: 'Project'
      },
      OWNER_NAME: {
        NAME: 'Owner Name'
      },
      MANAGER_NAME: {
        NAME: 'Manager'
      },
      STATUS: {
        NAME: 'Status',
        OPTIONS: {
          ACTIVE: 'Active',
          PENDING: 'Pending',
          INACTIVE: 'Inactive'
        }
      },
      PAYMENT_PERIOD: {
        NAME: 'Payment Period'
      },
      PAYMENT_AMOUNT: {
        NAME: 'Payment Amount',
        PLACEHOLDER: 'Not Set'
      },
      PROPERTY_VALUE: {
        NAME: 'Property Value',
        PLACEHOLDER: 'Not Set'
      },
      MANAGEMENT_FEE: {
        NAME: 'M. Fee',
        PLACEHOLDER: 'No Fee'
      },
      START_DATE: {
        NAME: 'Start Date'
      },
      END_DATE: {
        NAME: 'End Date'
      },
      ACTIONS: {
        NAME: 'Actions'
      }
    },
    LIST: {
      NO_ITEMS: 'No contracts found.',
      HEADER: {
        ACTIONS: {
          CREATE: 'Create'
        }
      },
      ITEM: {
        ACTIONS: {
          VIEW: 'View'
        }
      }
    },
    GRID: {
      NO_ITEMS: 'No contracts found.'
    }
  },
  CONTRACT_VIEW: {
    TITLE: 'Contract',
    SUB_TITLE: 'Contract Details'
  },
  ASSET_LIST: {
    TITLE: 'Assets',
    SUB_TITLE: "Here's a list of all available assets",
    COLUMNS: {
      ID: {
        NAME: 'ID'
      },
      ASSET: {
        NAME: 'Name'
      },
      PROJECT: {
        NAME: 'Project'
      },
      OWNER: {
        NAME: 'Owner'
      },
      MANAGER: {
        NAME: 'Manager'
      },
      STATE: {
        NAME: 'State'
      },
      STATUS: {
        NAME: 'Status'
      },
      ACTIONS: {
        NAME: 'Actions'
      }
    },
    LIST: {
      NO_ITEMS: 'No assets found.',
      HEADER: {
        ACTIONS: {
          CREATE: 'Create'
        }
      },
      ITEM: {
        ACTIONS: {
          VIEW: 'View',
          EDIT: 'Edit'
        }
      }
    },
    GRID: {
      NO_ITEMS: 'No assets found.',
      PAGINATION: {
        ITEMS_NAME: 'Assets'
      }
    }
  }
};

export default enJSON;
